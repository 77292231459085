import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, CardTitle, Alert, Button } from "reactstrap"
import MetaTags from "react-meta-tags"

import { useSelector, useDispatch } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"

import { getCouponsReport as onGetCouponsReport } from "store/actions"
import { isEmpty, map, stubString } from "lodash"
import moment from "moment"
import zipcelx from "zipcelx"

const DatatableTables = props => {
  const dispatch = useDispatch()
  const { error, data } = useSelector(state => ({
    error: state.Coupon?.error,
    data: state.Coupon?.data || [],
  }))
  const step = 100000
  const [startStep, setStartStep] = useState(0)
  const [stopStep, setStopStep] = useState(step)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(onGetCouponsReport())
  }, [dispatch])

  const handleDate = date => {
    if (isEmpty(date)) {
      return "--"
    }
    return moment(date).format("DD.MM.YYYY")
  }
  const handleDateTime = date => {
    if (isEmpty(date)) {
      return "--"
    }
    return moment(date).add(3, "hours").format("DD.MM.YYYY HH:mm")
  }

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row) => cell.padStart(7, "0"),
    },
    // {
    //   dataField: "faturaNo",
    //   text: "Fatura No",
    //   sort: true,
    // },
    {
      dataField: "firstName",
      text: "Adı",
      sort: true,
    },
    {
      dataField: "lastName",
      text: "Soyadı",
      sort: true,
    },
    {
      dataField: "dateOfBirth",
      text: "Doğum Tarihi",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Telefon",
      sort: true,
    },
    {
      dataField: "country",
      text: "İl",
      sort: true,
    },
    {
      dataField: "town",
      text: "İlçe",
      sort: true,
    },
    {
      dataField: "address",
      text: "Adres",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) =>
        isEmpty(cell)
          ? ""
          : cell.length > 15
          ? cell.substring(0, 15) + "..."
          : cell,
    },
    {
      dataField: "createdAt",
      text: "İşlem Tarihi",
      sort: true,
    },
    {
      dataField: "status",
      text: "Durum",
      sort: true,
      csvFormatter: (cell, row) => (cell == "ok" ? "Onaylı" : "İptal"),
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) => (cell == "ok" ? "Onaylı" : "İptal"),
    },
    {
      dataField: "description",
      text: "Açıklama",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row) =>
        isEmpty(cell)
          ? ""
          : cell.length > 15
          ? cell.substring(0, 15) + "..."
          : cell,
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: isEmpty(data) ? 0 : data.length, // replace later with size(customers),
    custom: true,
  }

  const exportExcel = () => {
    setLoading(true)
    const config = {
      filename:
        "Kutup Çekiliş - " +
        moment().format("DD.MM.YYYY") +
        `(${startStep}-${stopStep})`,
      sheet: {
        data: [],
      },
    }
    const dataSet = config.sheet.data
    dataSet.push([
      { value: "Id", type: "string" },
      { value: "Adı", type: "string" },
      { value: "Soyadı", type: "string" },
      { value: "Doğum Tarihi", type: "string" },
      { value: "Telefon", type: "string" },
      { value: "İl", type: "string" },
      { value: "İlçe", type: "string" },
      { value: "Adres", type: "string" },
      { value: "İşlem Tarihi", type: "string" },
      { value: "Durum", type: "string" },
      { value: "Açıklama", type: "string" },
    ])

    if (!isEmpty(data)) {
      setLoading(true)
      console.log(startStep, stopStep)
      for (let i = startStep; i < stopStep; i++) {
        if (data.length > i) {
          let item = data[i]
          dataSet.push([
            { value: item.id, type: "string" },
            { value: item.firstName, type: "string" },
            { value: item.lastName, type: "string" },
            { value: item.dateOfBirth, type: "string" },
            { value: item.phone, type: "string" },
            { value: item.country, type: "string" },
            { value: item.town, type: "string" },
            { value: item.address, type: "string" },
            { value: item.createdAt, type: "string" },
            {
              value: item.status == "ok" ? "Onaylı" : "İptal",
              type: "string",
            },
            { value: item.description, type: "string" },
          ])
        }
      }

      zipcelx(config)
        .then(() => {
          setLoading(false)
        })
        .catch(err => {
          console.error("hata olustu", err)
          setLoading(false)
          stopStep = data.length
        })
        .finally(() => {
          setStartStep(startStep + step)
          if (stopStep + step > data.length) setStopStep(data.length)
          else setStopStep(stopStep + step)
        })
    }
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: isEmpty(data) ? 0 : data.length },
  ]

  const { SearchBar } = Search

  const loadingView = () => {
    return (
      <div className="loading">
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Rapor</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Tablolar" breadcrumbItem="Rapor" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">Rapor</CardTitle> */}

                  {error && error ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={data}
                        search
                        exportCSV={{
                          fileName: "report.csv",
                          noAutoBOM: false,
                          blobType: "text/plain;charset=utf-8",
                          exportAll: true,
                        }}
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Button
                              className="ms-4"
                              type="button"
                              color="success"
                              onClick={() => {
                                exportExcel()
                              }}
                            >
                              <i className="mdi mdi-microsoft-excel"></i> Export
                              XLSX
                            </Button>
                            {/* <ExportCSVButton
                              {...toolkitProps.csvProps}
                              className="btn btn-success"
                            >
                              <i
                                className="mdi mdi-microsoft-excel me-2"
                                style={{ fontSize: 18, padding: 0 }}
                              ></i>
                              Export CSV
                            </ExportCSVButton> */}
                            <hr />
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      placeholder="Ara..."
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            {isEmpty(data) || loading ? loadingView() : null}
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  {/* <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  /> */}
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DatatableTables))
